@font-face {
  font-family: "New Baskerville ITC Pro";
  src: url("./fonts/NewBaskervilleITCPro-Italic.woff2") format("woff2"),
    url("./fonts/NewBaskervilleITCPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  /*font-display: swap;*/
}

@font-face {
  font-family: "Untitled Sans";
  src: url("./fonts/UntitledSans-Regular.woff2") format("woff2"),
    url("./fonts/UntitledSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  /*font-display: swap;*/
}

body {
  --black: #000000;
  --ash-black: #222;
  --white: #f4eee2;
  --white: #f3eeda;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--white);
  --accent: var(--ash-black);

  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
}

* {
  font-family: "Untitled Sans", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

.scroll {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100px;
  text-align: center;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: -1;
}

.scroll * {
}

.scroll-text {
  font-family: "New Baskerville ITC Pro", "Baskerville", serif;
  font-style: italic;
  font-size: 4vmin;
  display: block;
  padding: 2vmin;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease;
}

.scroll-line {
  display: inline-block;
  height: 15vmin;
  border-left: 1px solid;
  width: 1px;
  margin: 0;
  opacity: 0;
  transform-origin: 0 100%;
  transform: scaleY(0);
  transition: transform 1s ease, opacity 1s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes scaleDown {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

.App--top .scroll-text {
  opacity: 1;
}

.App--top .scroll-line {
  transform: scaleY(1);
  opacity: 1;
}

.App--scrolled .scroll-text {
  opacity: 0;
  transform: translateY(50%);
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.App--scrolled .scroll-line {
  transition: transform 0.4s ease, opacity 0.3s ease;
  transform: scaleY(0);
  opacity: 0;
}

.banner {
  opacity: 0;
  transition: opacity 1s ease;
}
.App--progressing .banner {
  /*animation: fadeIn 1s ease forwards;*/
  transition: opacity 1s 2s ease;
  opacity: 1;
}

.App--ending .banner {
  /*animation: fadeOut 0.4s ease forwards;*/
  opacity: 0;
}

.item {
  /*background: white;*/
  position: relative;
  z-index: 1;
}
.item .video {
  border: 1vmin solid white;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: white;
  transition: transform 1s ease, box-shadow 1s ease;
  transform: scale3d(0.95, 0.95, 1);
}
.item .video.active {
  transform: scale(1);
  transform: scale3d(1, 1, 1);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}
.item video {
  display: block;
  width: 100%;
}
.item svg {
  width: 100%;
  height: auto;
}

.ending {
  position: relative;
  height: 100vh;
}
.ending div {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  font-family: "New Baskerville ITC Pro", "Baskerville", serif;
  font-style: italic;
  font-size: 4vmin;
  opacity: 0;
  z-index: -1;
  transform: translateY(-30%);
}
.App--progressing .ending div {
  transition: opacity 1s ease;
  opacity: 0;
}
.App--ending .ending div {
  transition: opacity 2s 0.5s ease;
  opacity: 1;
}

.handle {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 20px;
  padding-bottom: 40px;
  color: var(--accent);
  pointer-events: none;
}

.handle a {
  display: inline-block;
  border-bottom: 1px solid transparent;
  transition: border 0.4s ease;
  color: var(--accent);
  text-decoration: none;
}

.handle a:hover {
  border-color: var(--accent);
}

.App--progressing .handle {
  transition: opacity 1s ease;
  opacity: 0;
}
.App--ending .handle {
  transition: opacity 2s 0.5s ease;
  opacity: 1;
  pointer-events: all;
}

.sound {
  background: white;
  padding: 20px;
  border-radius: 100px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 4;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  transition: opacity 1s ease;
}
.sound svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*.App--top .sound{
  transform: scaleY(1);
  opacity: 0;
}*/

.App--scrolled .sound {
  opacity: 1;
}
.App--ending .sound {
  opacity: 0;
  pointer-events: none;
}

h1,
h2,
h3 {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0 40px;
}

h2 {
  font-weight: 400;
  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
}

footer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vmin;

  font-size: 14px;
  line-height: 14px;
}

footer svg {
  margin-right: 0px;
  width: 15vmin;
}

footer a {
  text-decoration: none;
  color: var(--accent);
}

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: none;
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}

html {
  scroll-snap-type: y mandatory;
}

h1 {
  font-size: 56px;
  font-weight: 600;
  letter-spacing: -3px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0;
}

h2 {
  margin: 0;
  color: var(--accent);
  left: calc(50% + 130px);
  font-size: 56px;
  font-weight: 600;
  letter-spacing: -3px;
  line-height: 1.2;
  position: absolute;
}

section {
}

img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  transform: translateY(-0.7vmax);
}

.parallax.alt {
  transform: translateY(0.7vmax);
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 8vmax;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax.alt .scroller {
  font-weight: normal;
}

.parallax span {
  display: block;
  margin-right: 0.2em;
}
